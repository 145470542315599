<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve">
    <g>
      <g>
        <path fill="#E01E5A" d="M5,15.2c0,1.4-1.1,2.5-2.5,2.5S0,16.6,0,15.2c0-1.4,1.1-2.5,2.5-2.5H5V15.2z" />
        <path fill="#E01E5A" d="M6.3,15.2c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v6.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5 C6.3,21.5,6.3,15.2,6.3,15.2z" />
      </g>
      <g>
        <path fill="#36C5F0" d="M8.8,5C7.4,5,6.3,3.9,6.3,2.5S7.4,0,8.8,0s2.5,1.1,2.5,2.5V5H8.8z" />
        <path fill="#36C5F0" d="M8.8,6.3c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5H2.5C1.1,11.4,0,10.2,0,8.8s1.1-2.5,2.5-2.5 C2.5,6.3,8.8,6.3,8.8,6.3z" />
      </g>
      <g>
        <path fill="#2EB67D" d="M19,8.8c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5H19V8.8z" />
        <path fill="#2EB67D" d="M17.7,8.8c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5 V8.8z" />
      </g>
      <g>
        <path fill="#ECB22E" d="M15.2,19c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V19H15.2z" />
        <path fill="#ECB22E" d="M15.2,17.7c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h6.3c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5 H15.2z" />
      </g>
    </g>
  </svg>
</template>
